module.exports = [{
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-140855676-2"
  }
}, {
  plugin: require('/opt/build/repo/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];