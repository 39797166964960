// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-pages-404-jsx": function componentSrcPages404Jsx() {
    return import("/opt/build/repo/src/pages/404.jsx"
    /* webpackChunkName: "component---src-pages-404-jsx" */
    );
  },
  "component---src-pages-contact-jsx": function componentSrcPagesContactJsx() {
    return import("/opt/build/repo/src/pages/contact.jsx"
    /* webpackChunkName: "component---src-pages-contact-jsx" */
    );
  },
  "component---src-pages-index-jsx": function componentSrcPagesIndexJsx() {
    return import("/opt/build/repo/src/pages/index.jsx"
    /* webpackChunkName: "component---src-pages-index-jsx" */
    );
  }
};